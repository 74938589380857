.login-block {
	background:#000000;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom, #000000, #929292);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, #000000, #e5e3ec);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	float: left;
	width: 100%;
	padding: 120px 0;
    height: 100vh;
}
@media only screen and (max-width: 600px) {
    .login-block {
        padding: 60px 0;
    }
  }
.banner-sec {
	background: url(../../../Images/bg.jpg) no-repeat left bottom;
	background-size: cover;
	min-height: 500px;
	border-radius: 0 10px 10px 0;
	padding: 0;
}

.container {
	background: #000000;
	border-radius: 10px;
	box-shadow: 15px 20px 0px rgba(0, 0, 0, 0.1);
}

.carousel-inner {
	border-radius: 0 10px 10px 0;
}

.carousel-caption {
	text-align: left;
	left: 5%;
}

.login-sec {
	padding: 50px 30px;
	position: relative;
}

.login-sec .copy-text {
	position: absolute;
	width: 80%;
	bottom: 20px;
	font-size: 13px;
	text-align: center;
    color: white;
}

.login-sec .copy-text i {
	color: #ffffff;
}

.login-sec .copy-text a {
	color: #ffffff;
}

.login-sec h2 {
	margin-bottom: 30px;
	font-weight: 800;
	font-size: 30px;
	color: #ffffff;
}

.login-sec h2:after {
	content: " ";
	width: 100px;
	height: 5px;
	background: #FEB58A;
	display: block;
	margin-top: 20px;
	border-radius: 3px;
	margin-left: auto;
	margin-right: auto
}

.btn-login {
	background: #000000;
	color: #fff;
	font-weight: 600;
}

.banner-text {
	width: 70%;
	position: absolute;
	bottom: 40px;
	padding-left: 20px;
}

.banner-text h2 {
	color: #fff;
	font-weight: 600;
}

.banner-text h2:after {
	content: " ";
	width: 100px;
	height: 5px;
	background: #FFF;
	display: block;
	margin-top: 20px;
	border-radius: 3px;
}

.banner-text p {
	color: #fff;
}
.text-uppercase{
    color: white;
}
.form-check-label{
    padding-left: 0;
}

.login-sec h2:after {
    content: " ";
    width: 100px;
    height: 5px;
    background: #adb5bd;
    display: block;
    margin-top: 20px;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
}
.cus-btn{
    color: white;
}